import {
  DateField,
  List,
  Table,
  Avatar,
  Space,
  useEditableTable,
  Form,
  Icons,
  Button,
  useDrawerForm,
  EditButton,
  CreateButton,
  FormProps,
  Checkbox
} from '@pankod/refine-antd'
import { GetOneResponse, HttpError, useSelect, useTranslate } from '@pankod/refine-core'
import { definitions, ItemMarketplace, Order } from 'interfaces'
import { QueryObserverResult } from '@tanstack/react-query'
import { CUSTOM_ORDER_MARKETPLACE_ID, DEFAULT_DATE_TIME_FORMAT } from 'utility/constants'
import { currency } from 'utility/currency'
import { EyeOutlined } from '@ant-design/icons'
import { handleDisableItem, supabaseClient } from 'utility'
import { ItemsActions } from 'components/items/actions'
import { useEffect, useState } from 'react'
import { calculateItemSups } from 'utility/orderCalculator'

type OrderDetailsProps = {
  order: definitions['Order']
  orderQuery: QueryObserverResult<GetOneResponse<Order>, unknown>
  refetchSup?: any
}

export const OrderItems: React.FC<OrderDetailsProps> = (props) => {
  const t = useTranslate()

  const order = props?.order
  const { orderQuery, refetchSup } = props

  const [loading, setLoading] = useState<boolean>(false)
  const [reducedIva, setReducedIva] = useState<number>(0)
  const [reducedIvaEU, setReducedIvaEU] = useState<number>(0)
  const [reducedIvaShipment, setReducedIvaShipment] = useState<number>(0)
  const [reducedIvaSecondHand, setReducedIvaSecondHand] = useState<number>(0)
  const [reducedIvaBreakdown, setReducedIvaBreakdown] = useState<number>(0)
  const [price, setPrice] = useState<number>(0)
  const [insurancePrice, setInsurancePrice] = useState<number>(0)
  const [tariffPrice, setTariffPrice] = useState<number>(0)
  const [igicPrice, setIgicPrice] = useState<Number>(0)

  const {
    tableProps,
    formProps,
    setId: setEditId,
    tableQueryResult
  } = useEditableTable<definitions['items'], HttpError>({
    resource: 'items',
    initialSorter: [
      {
        field: 'created_at',
        order: 'desc'
      }
    ],
    permanentFilter: [
      {
        field: 'order_id',
        operator: 'eq',
        value: order?.id
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    initialPageSize: 4,
    syncWithLocation: false,
    metaData: {
      select: 'id, order_id, code, name, image, description, model, size, color, quantity, price, iva, tariff_percent, tariff_price, igic_percent, igic_price, syniva_price, has_accessories, supplement_type, shipping_price_to_madrid, url, created_at, item_category_id, disabled, marketplace(id, name, original_url), issue_id, delivered_warehouse, delivered_warehouse_date'
    }
  })

  const { options: itemCategoriesOptions } = useSelect<definitions['item_categories']>({
    resource: 'item_categories',
    fetchSize: 10000,
    optionLabel: 'code_desc',
    optionValue: 'id',
    filters: [
      {
        field: 'show_in_search',
        operator: 'eq',
        value: true
      }
    ],
    sort: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  })

  const getConditionalClassName = (record: definitions['items']) => {
    if (record.disabled) {
      return 'disabled-item'
    }
    return ''
  }

  const checkLastItem = (disabled: Boolean) => {
    if (!disabled) {
      return 0
    }

    let itemsEnabled = 0
    tableProps?.dataSource?.forEach((item) => {
      if (!item?.disabled) {
        itemsEnabled += 1
      }
    })

    if (itemsEnabled === 1) {
      return order?.shipping_price
    }
    return 0
  }

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
    close: createClose
  } = useDrawerForm<definitions['items']>({
    action: 'create',
    resource: 'items',
    redirect: false
  })

  createFormProps.initialValues = { order_id: order.id, user_email: order.user.email, marketplace_id: order.marketplace_id.id }

  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: editShow,
    close: editClose,
    saveButtonProps: editSaveButtonProps
  } = useDrawerForm<definitions['items']>({
    action: 'edit',
    resource: 'items',
    redirect: false
  })

  const {
    formProps: replaceFormProps,
    drawerProps: replaceDrawerProps,
    show: replaceShow,
    close: replaceClose,
    saveButtonProps: replaceSaveButtonProps
  } = useDrawerForm<definitions['items']>({
    action: 'clone',
    resource: 'items',
    redirect: false
  })

  useEffect(() => {
    getTariffPrice()
  }, [tableProps])

  const getTariffPrice = async () => {
    const { data: supplements } = await supabaseClient.from('supplements')
      .select('id, description, supplement')
      .eq('order_id', order.id)
      .eq('deleted', false)

    if (supplements && supplements.length > 0) {
      if (supplements.find((x) => x.description === 'igic')) setIgicPrice(supplements.filter((x) => x.description === 'igic').map((x: any) => x.supplement || 0).reduce((a: number, b: number) => a + b, 0))
      else setIgicPrice(0)

      if (supplements?.find((x) => x.description === 'priceChange')) setPrice(supplements.filter((x) => x.description === 'priceChange').map((x: any) => x.supplement || 0).reduce((a: number, b: number) => a + b, 0))
      else setPrice(0)

      if (supplements?.find((x) => x.description === 'shippingInsurancePrice')) setInsurancePrice(supplements.filter((x) => x.description === 'shippingInsurancePrice').map((x: any) => x.supplement || 0).reduce((a: number, b: number) => a + b, 0))
      else setInsurancePrice(0)

      if (supplements?.find((x) => x.description === 'tariff')) setTariffPrice(supplements.filter((x) => x.description === 'tariff').map((x: any) => x.supplement || 0).reduce((a: number, b: number) => a + b, 0))
      else setTariffPrice(0)

      if (supplements?.find((x) => x.description?.includes('reduced'))) {
        setReducedIva(supplements.filter((x) => x.description === 'reducedIva').map((x: any) => x.supplement || 0).reduce((a: number, b: number) => a + b, 0) || 0)
        setReducedIvaSecondHand(supplements.filter((x) => x.description === 'reducedIvaSecondHand').map((x: any) => x.supplement || 0).reduce((a: number, b: number) => a + b, 0) || 0)
        setReducedIvaBreakdown(supplements.filter((x) => x.description === 'reducedIvaBreakdown').map((x: any) => x.supplement || 0).reduce((a: number, b: number) => a + b, 0) || 0)
        setReducedIvaEU(supplements.filter((x) => x.description === 'reducedIvaEU').map((x: any) => x.supplement || 0).reduce((a: number, b: number) => a + b, 0) || 0)
        setReducedIvaShipment(supplements.filter((x) => x.description === 'reducedIvaShipment').map((x: any) => x.supplement || 0).reduce((a: number, b: number) => a + b, 0) || 0)
      } else {
        setReducedIva(0)
        setReducedIvaEU(0)
        setReducedIvaShipment(0)
        setReducedIvaSecondHand(0)
        setReducedIvaBreakdown(0)
      }
    }
  }

  const titleFormatted = () => {
    const keyString = `${Number(tariffPrice) + Number(igicPrice) + Number(price) + Number(reducedIva) + Number(insurancePrice)}`
    return (<div key={keyString}>
      <p>{t('item.items')}</p>
      {(Number(tariffPrice) !== 0) && <p style={{ fontSize: 16, marginTop: '-12px' }}>{t('item.fields.tariffPriceTotal')} <b style={{ fontWeight: 700 }}>{tariffPrice.toFixed(2)}€</b></p>}
      {(Number(igicPrice) !== 0) && <p style={{ fontSize: 16, marginTop: '-12px' }}>{t('item.fields.igicPriceTotal')} <b style={{ fontWeight: 700 }}>{igicPrice.toFixed(2)}€</b></p>}
      {(Number(price) !== 0) && <p style={{ fontSize: 16, marginTop: '-12px' }}>{t('item.fields.priceDiffTotal')} <b style={{ fontWeight: 700 }}>{price.toFixed(2)}€</b></p>}
      {(Number(reducedIva) !== 0) && <p style={{ fontSize: 16, marginTop: '-12px' }}>{t('item.fields.reducedIva')} <b style={{ fontWeight: 700 }}>{reducedIva.toFixed(2)}€</b></p>}
      {(Number(reducedIvaSecondHand) !== 0) && <p style={{ fontSize: 16, marginTop: '-12px' }}>{t('item.fields.reducedIvaSecondHand')} <b style={{ fontWeight: 700 }}>{reducedIvaSecondHand.toFixed(2)}€</b></p>}
      {(Number(reducedIvaBreakdown) !== 0) && <p style={{ fontSize: 16, marginTop: '-12px' }}>{t('item.fields.reducedIvaBreakdown')} <b style={{ fontWeight: 700 }}>{reducedIvaBreakdown.toFixed(2)}€</b></p>}
      {(Number(reducedIvaEU) !== 0) && <p style={{ fontSize: 16, marginTop: '-12px' }}>{t('item.fields.reducedIvaEU')} <b style={{ fontWeight: 700 }}>{reducedIvaEU.toFixed(2)}€</b></p>}
      {(Number(reducedIvaShipment) !== 0) && <p style={{ fontSize: 16, marginTop: '-12px' }}>{t('item.fields.reducedIvaShipment')} <b style={{ fontWeight: 700 }}>{reducedIvaShipment.toFixed(2)}€</b></p>}
      {(Number(insurancePrice) !== 0) && <p style={{ fontSize: 16, marginTop: '-12px' }}>{t('item.fields.insurancePriceDiffTotal')} <b style={{ fontWeight: 700 }}>{insurancePrice.toFixed(2)}€</b></p>}
    </div>)
  }

  const handleDeliveredWarehouseChange = async (value: any, record: any, func?: Function) => {
    if (record.id) {
      await supabaseClient.from('items')
        .update({
          delivered_warehouse: value,
          delivered_warehouse_date: new Date(Date.now()).toISOString()
        })
        .eq('id', record.id)

      const { data } = await tableQueryResult.refetch()

      if (data && data.data) {
        const enableItems = data.data.filter((i: definitions['items']) => !i.disabled)
        const incompletedItems = enableItems.filter((i: definitions['items']) => !i.delivered_warehouse)
        await supabaseClient.from('orders')
          .update({ completed: (incompletedItems.length === 0) })
          .eq('id', record.order_id)
      }
      await orderQuery.refetch()
    }
  }

  return (
    <List
      title={titleFormatted() as any}
      breadcrumb={false}
      headerButtons={
        <CreateButton onClick={() => createShow()}>
          {t('order.actions.addItem')}
        </CreateButton>
      }
    >
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          rowClassName={(record) => getConditionalClassName(record)}
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.classList.contains('item-category-id') || event.target.closest('.item-category-id')) {
                setEditId && setEditId(record.id)
              } else if (event.target.classList.contains('item-iva') || event.target.closest('.item-iva')) {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column<ItemMarketplace>
            fixed="left"
            title={t('item.fields.image')}
            dataIndex="image"
            key="image"
            align="center"
            render={(value, record) =>
              <a
                href={record.marketplace.id !== CUSTOM_ORDER_MARKETPLACE_ID ? record.marketplace.original_url + record.url : record.url}
                target='_blank' rel='noopener noreferrer'
              >
                {value ? <Avatar src={value} size={40} shape="square" /> : null}
              </a>
            }
          />
          <Table.Column key="id" dataIndex="id" title={t('item.fields.id')} />
          <Table.Column
            key="delivered_warehouse"
            dataIndex="delivered_warehouse"
            title={t('item.fields.delivered_warehouse')}
            render={(value: boolean, record: definitions['items']) => (
              <Space style={{ justifyContent: 'center' }}>
                <Checkbox defaultChecked={record.delivered_warehouse} onChange={(e: any) => handleDeliveredWarehouseChange(e.target.checked, record)} />
                {(record.delivered_warehouse && record.delivered_warehouse_date) && <DateField value={record.delivered_warehouse_date} format={DEFAULT_DATE_TIME_FORMAT} />}
              </Space>
            )}
            sorter
          />
          <Table.Column
            key="code"
            dataIndex="code"
            title={t('item.fields.code')}
          />
          <Table.Column
            key="name"
            dataIndex="name"
            title={t('item.fields.name')}
          />
          <Table.Column
            dataIndex="marketplace"
            title={t('item.fields.marketplace')}
            render={(mkt) => mkt?.name}
          />
          <Table.Column
            key="description"
            dataIndex="description"
            title={t('item.fields.description')}
            render= {(text) => <div style={{ width: '20vw' }}>{text}</div>}
          />
          <Table.Column
            key="model"
            dataIndex="model"
            title={t('item.fields.model')}
          />
          <Table.Column
            key="size"
            dataIndex="size"
            title={t('item.fields.size')}
          />
          <Table.Column
            key="color"
            dataIndex="color"
            title={t('item.fields.color')}
          />
          <Table.Column
            key="quantity"
            dataIndex="quantity"
            title={t('item.fields.quantity')}
            sorter
          />
          <Table.Column
            key="price"
            dataIndex="price"
            title={t('item.fields.price')}
            render={(value) => currency(value)}
            sorter
          />
          <Table.Column
            key="iva"
            dataIndex="iva"
            title={t('item.fields.iva')}
            className='item-iva'
          />
          <Table.Column
            key="syniva_price"
            dataIndex="syniva_price"
            title={t('order.fields.synivaPrice')}
            render={(value) => currency(value)}
            sorter
          />
          <Table.Column
            key="tariff_percent"
            dataIndex="tariff_percent"
            title={t('item.fields.tariffPercent')}
          />
          <Table.Column
            key="tariff_price"
            dataIndex="tariff_price"
            title={t('item.fields.tariffPrice')}
            render={(value) => currency(value)}
          />
          <Table.Column
            key="igic_percent"
            dataIndex="igic_percent"
            title={t('item.fields.igicPercent')}
          />
          <Table.Column
            key="igic_price"
            dataIndex="igic_price"
            title={t('item.fields.igicPrice')}
            render={(value) => currency(value)}
          />
          <Table.Column
            key="shipping_price_to_madrid"
            dataIndex="shipping_price_to_madrid"
            title={t('item.fields.shippingPriceToMadrid')}
            render={(value) => currency(value)}
            sorter
          />
          <Table.Column
            key="createdAt"
            dataIndex="created_at"
            title={t('item.fields.createdAt')}
            render={(value) => (
              <DateField value={value} format={DEFAULT_DATE_TIME_FORMAT} />
            )}
            sorter
          />
          <Table.Column
            dataIndex="item_category_id"
            title={t('item.fields.item_category')}
            className='item-category-id'
            render={(value) => {
              return <>
                <Form.Item style={{ minWidth: 200 }}>
                  {value ? itemCategoriesOptions.filter(ic => ic.value === value)[0]?.label : '----'}
                </Form.Item>
              </>
            }}
            sorter
          />
          <Table.Column<ItemMarketplace>
            fixed="right"
            title={t('table.actions')}
            dataIndex="actions"
            key="actions"
            align="center"
            render={(_, record) => {
              return <Space>
                <EditButton
                  onClick={() => editShow(record.id)}
                  hideText
                />
                <a
                  href={record.marketplace.id !== CUSTOM_ORDER_MARKETPLACE_ID ? record.marketplace.original_url + record.url : record.url}
                  target='_blank' rel='noopener noreferrer'
                >
                  <EyeOutlined
                    style={{
                      color: 'green',
                      fontSize: '15px',
                      border: '1px solid green',
                      borderRadius: 5,
                      padding: '3px 5px'
                    }} />
                </a>
                <Button
                  loading={loading}
                  icon={<Icons.ExclamationCircleOutlined style={{ color: 'grey' }} />}
                  style={{ backgroundColor: 'inherit', borderColor: 'grey' }}
                  size='small'
                  onClick={async () => {
                    setLoading(true)
                    try {
                      await handleDisableItem([record.id], !record?.disabled, checkLastItem(!record?.disabled), undefined, false, undefined)
                      const formProps: FormProps = {
                        initialValues: record
                      }
                      await calculateItemSups(formProps, order, order.id, record.price, record.iva || 21, record.quantity, null, refetchSup, false, true)
                      setLoading(false)
                    } catch (error) {
                      setLoading(false)
                    }
                    window.location.reload()
                  }}
                />
                <Button
                  icon={<Icons.RetweetOutlined style={{ color: 'grey' }} />}
                  style={{ backgroundColor: 'inherit', borderColor: 'grey' }}
                  size='small'
                  onClick={() => replaceShow(record.id)}
                />
                {record.issue_id && (
                  <Button
                    icon={<Icons.IssuesCloseOutlined style={{ color: 'red' }} />}
                    style={{ borderColor: 'red' }}
                    onClick={() => window.open(`/issues/show/${record.issue_id}`, '_blank', 'noopener,noreferrer')}
                  />
                )}
              </Space>
            }}
          />
        </Table>
      </Form>
      {order && <>
        <ItemsActions
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
          closeDrawer={createClose}
          tableQueryResult={tableQueryResult}
          orderId={order.id}
          orderQuery={orderQuery}
          marketplaceId={order.marketplace_id.id}
          userEmail={order.user.email}
          itemCategories={itemCategoriesOptions}
          order={order}
          refetchSup={refetchSup} />
        <ItemsActions
          edit={true}
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          closeDrawer={editClose}
          tableQueryResult={tableQueryResult}
          orderId={order.id}
          orderQuery={orderQuery}
          marketplaceId={order.marketplace_id.id}
          userEmail={order.user.email}
          itemCategories={itemCategoriesOptions}
          order={order}
          refetchSup={refetchSup} />
        <ItemsActions
          replace={true}
          drawerProps={replaceDrawerProps}
          formProps={replaceFormProps}
          saveButtonProps={replaceSaveButtonProps}
          closeDrawer={replaceClose}
          tableQueryResult={tableQueryResult}
          orderId={order.id}
          orderQuery={orderQuery}
          marketplaceId={order.marketplace_id.id}
          userEmail={order.user.email}
          itemCategories={itemCategoriesOptions}
          order={order}
          refetchSup={refetchSup} />
      </>}
    </List>
  )
}
